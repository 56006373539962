/* global Component */
class addressCreateComponent extends Component {

    static name() {
        return "addressCreateComponent";
    }

    static componentName() {
        return "addressCreateComponent";
    }

    getProps() {
        return [];
    }

    data() {
        return {
            isloading: false,
            customerAddresses: [],
            selectedAddress: {
                Code: null,
                Name: '',
                Street:null,
                StreetNumber:null,
                Floor:null,
                Apartment:null,
                Address: '',
                Country: '',
                ProvinceCode: '',
                Locality: '',
                ZipCode:'',
                City: '',
                Latitude:null,
                Longitude:null,
                normalize:false
            },
            alertErrorAddress: null
        };
    }

    mounted() {
        return function () {
            this.subscribeEvent("addressNeedConfirmations",this.closeMap);
            $(this.$refs.newDeliveryModal).on('hidden.bs.modal',  (e)=>{
                this.$store.commit('updateSelectLinkto', {table:'modalAddress_Province',value:null});
                this.$store.commit('updateSelectLinkto', {table:'modalAddress_Country',value:null});
                this.$store.commit('updateSelectLinkto', {table:'modalAddress_City',value:null});
                this.$store.commit('updateSelectLinkto', {table:'modalAddress_ZipCode',value:null});
                this.isloading= false;
                this.customerAddresses= [];
                this.selectedAddress= {
                    Code: null,
                    Name: '',
                    Street:null,
                    StreetNumber:null,
                    Floor:null,
                    Apartment:null,
                    Address: '',
                    Country: '',
                    ProvinceCode: '',
                    Locality: '',
                    ZipCode:'',
                    City: '',
                    Latitude:null,
                    Longitude:null,
                    normalize:false
                },
                this.alertErrorAddress= null;
            })
        };
    }

    getWatch() {
        return {
            '$store.state.CustomerAddresses': function () {
                this.clearForm();
            },
            'selectedAddress.Street': function () {
                this.buildAddress();
            },
            'selectedAddress.StreetNumber': function () {
                this.buildAddress();
            },
            'selectedAddress.Floor': function () {
                this.buildAddress();
            },
            'selectedAddress.Apartment': function () {
                this.buildAddress();
            },
        };
    }

    getComputed() {
        return {
            getCustomerAddresses: function () {
                this.customerAddresses = this.$store.getters.getCustomerAddresses;
                return this.customerAddresses;
            },
            provinceFilter() {
                let filter = {};
                if (this.selectedAddress.Country)
                    filter['Country'] = this.selectedAddress.Country;
                return filter;
            },
            cityFilter() {
                let filter = {};
                if (this.selectedAddress.ProvinceCode)
                    filter['Province'] = this.selectedAddress.ProvinceCode;
                return filter;
            },
            zipCodeFilter() {
                let filter = {};
                if (this.customer.LocalityCode)
                    filter['LocalityCode'] = this.customer.LocalityCode;
                return null;
            },
            Button: function () {
                return `btn btn-custom`;
            },
        };
    }

    getMethods() {
        return {
            confirmAddressTrigger:this.confirmAddressTrigger,
            closeMap:this.closeMap,
            viewInMap:this.viewInMap,
            triggerSaveAction:this.triggerSaveAction,
            normalizeAddress:this.normalizeAddress,
            buildAddress:this.buildAddress,
            loadCustomerAddresses:this.loadCustomerAddresses,
            updateSelectValues:this.updateSelectValues,
            activeDeliveryAddress:this.activeDeliveryAddress,
            registerNewAddress:this.registerNewAddress,
            editAddress:this.editAddress,
            disableAddress:this.disableAddress,
            clearForm:this.clearForm,
        };
    }

    confirmAddressTrigger(address){
        if(address.Code == this.selectedAddress.Code)
            this.selectedAddress = address;
    }

    closeMap(){
        $('#newDeliveryModal').modal('hide');
        return null;
    }

    viewInMap(){
        this.confirmAddress=true;
        this.emitEvent("addressNeedConfirmations",this.selectedAddress);
    }

    async triggerSaveAction(eventAddress){
        let address = this.selectedAddress;
        if(eventAddress)
            address=eventAddress
        let openMap = address.normalize;
        if(!address.Longitude || !address.Latitude || address.normalize) {
            this.selectedAddress=address;
            await this.normalizeAddress();
        }
        if(openMap) {
            this.viewInMap();
        }
        else {
            if (this.activeDeliveryAddress(this.selectedAddress.Code))
                this.editAddress();
            else
                this.registerNewAddress();
        }
    }

    async normalizeAddress(eventAddress){
        let address = this.selectedAddress;
        if(eventAddress)
            address=eventAddress
        let response = await axios.post('/ecommerce/api/getLocation',{record:address});
        if(response.data.success) {
            if(!eventAddress) {
                this.selectedAddress = response.data.record;
                this.selectedAddress.normalize = false;
            }
            eventAddress=response.data.record;
            eventAddress.normalize = false;
        }
        return eventAddress;
    }

    buildAddress(){
        let newAddress = '';
        if (this.selectedAddress.Street)
            newAddress = this.selectedAddress.Street + " ";
        if (this.selectedAddress.StreetNumber)
            newAddress += this.selectedAddress.StreetNumber + " ";
        if (this.selectedAddress.Floor)
            newAddress += this.selectedAddress.Floor + " ";
        if (this.selectedAddress.Apartment)
            newAddress += this.selectedAddress.Apartment + " ";
        this.selectedAddress.Address = newAddress;
    }

    loadCustomerAddresses() {
        this.isloading = true;
        this.$store.dispatch('loadCustomerAddresses');
        this.isloading = false;
    }

    updateSelectValues(field) {
        if (field === 'Country')
            this.selectedAddress.Country = this.$store.getters.getSelectLinkToValue('modalAddress_Country');
        if (field === 'Province')
            this.selectedAddress.ProvinceCode = this.$store.getters.getSelectLinkToValue('modalAddress_Province');
        if (field === 'City')
            this.selectedAddress.City = this.$store.getters.getSelectLinkToValue('modalAddress_City');
        if (field === 'ZipCode')
            this.selectedAddress.ZipCode = this.$store.getters.getSelectLinkToValue('modalAddress_ZipCode');

    }
    activeDeliveryAddress(addressCode) {
        if (this.selectedAddress.Code != null) {
            if (this.selectedAddress.Code === addressCode)
                return true;
        }
        return false;
    }

    async registerNewAddress() {
        this.alertErrorAddress = null;
        this.selectedAddress.Name = this.selectedAddress.Address;
        if (!this.selectedAddress.Street)
            this.alertErrorAddress = ['Please fill the field {{$1}}', "Street"];
        if (!this.selectedAddress.StreetNumber)
            this.alertErrorAddress = ['Please fill the field {{$1}}', "StreetNumber"];
        else if (!this.selectedAddress.ProvinceCode)
            this.alertErrorAddress = ['Please fill the field {{$1}}', "Province"];
        else if (!this.selectedAddress.City)
            this.alertErrorAddress = ['Please fill the field {{$1}}', "City"];
        else if (!this.selectedAddress.Locality)
            this.alertErrorAddress = ['Please fill the field {{$1}}', "Locality"];
        else if (!this.selectedAddress.ZipCode)
            this.alertErrorAddress = ['Please fill the field {{$1}}', "ZipCode"];

        if (!this.alertErrorAddress) {
            this.loading=true;
            this.$store.dispatch('AddAddressCustomer', this.selectedAddress).then((response)=>{
                if(response.success) {
                    this.loading=false;
                    $(this.$refs.newDeliveryModal).modal("hide");
                }
            });
        }
    }

    async editAddress() {
        this.alertErrorAddress = null;
        this.selectedAddress.Name = this.selectedAddress.Address;

        if (!this.selectedAddress.Address)
            this.alertErrorAddress = ['Please fill the field {{$1}}', "Address"];
        else if (!this.selectedAddress.ProvinceCode)
            this.alertErrorAddress = ['Please fill the field {{$1}}', "Province"];
        else if (!this.selectedAddress.City)
            this.alertErrorAddress = ['Please fill the field {{$1}}', "City"];

        if (!this.alertErrorAddress) {
            this.$store.dispatch('editAddressCustomer', this.selectedAddress);
        }
    }

    disableAddress () {
        this.$store.dispatch('disableAddress', this.selectedAddress);
    }

    clearForm() {
        for(let key of Object.keys(this.selectedAddress))
            this.selectedAddress[key]=null;
        this.selectedAddress.Country = this.$store.getters.getOurSettingsCountry;
    }

    getTemplate() {
        return `
            <div class="modal" tabindex="-1" role="dialog" :aria-labelledby="tr('Delivery Address')" aria-hidden="true" ref="newDeliveryModal" id="newDeliveryModal" >
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title">{{tr("Delivery Address")}}</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                              <addressesManagerComponent :showAddressList="false" />
                        </div>
                    </div>
                </div>
            </div>`;
    }

    getTemplate2() {
        return `
            <div class="modal" tabindex="-1" role="dialog" ref="newDeliveryModal" id="newDeliveryModal" >
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title">{{tr("Delivery Address")}}</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <div class="container-fluid">
                              <div class="row">
                                <div class="col-12">
                                    <div class="col-sm-12" v-if="alertErrorAddress">
                                        <div ref='errorRegisterFrom' class="alert alert-danger alert-dismissible fade show" role="alert" >
                                           <strong>{{tr(alertErrorAddress)}}!</strong>
                                           <button type="button" class="close" data-dismiss="alert" aria-label="Close" @click="alertErrorAddress = null">
                                            <span aria-hidden="true">&times;</span>
                                           </button>
                                       </div>
                                  </div>
                                  <div class="form-group" >
                                       <label for="selectedAddress" class="form-label">{{tr('Street')}}</label>
                                       <input id="selectedAddressStreet" v-model="selectedAddress.Street" type="text" class="form-control" aria-autocomplete="none" autocomplete="none">
                                  </div>
                                  <div class="form-group" >
                                       <label for="selectedAddress" class="form-label">{{tr('Number')}}</label>
                                       <input id="selectedAddressNumber" v-model="selectedAddress.StreetNumber" type="text" class="form-control" aria-autocomplete="none" autocomplete="none">
                                  </div>
                                  <div class="row">
                                      <div class="form-group col-6" >
                                           <label for="selectedAddress" class="form-label">{{tr('Floor')}} ({{tr('Optional')}})</label>
                                           <input id="selectedAddressFlat" v-model="selectedAddress.Floor" type="text" class="form-control" aria-autocomplete="none" autocomplete="none">
                                      </div>
                                       <div class="form-group col-6" >
                                           <label for="selectedAddress" class="form-label">{{tr('Apartment')}} ({{tr('Optional')}})</label>
                                           <input id="selectedAddress" v-model="selectedAddress.Apartment" type="text" class="form-control" aria-autocomplete="none" autocomplete="none" />
                                      </div>
                                  </div>
                                  <div class="form-group d-none">
                                        <label for="customerCountry" class="form-label">{{tr('Country')}}</label>
                                        <selectLinkToComponent ref="modalAddress_Country" recordKey="modalAddress"  :table="'Country'" :paste="'Code'" :showField="'Name'" :selected="selectedAddress.Country" v-on:update-value="updateSelectValues('Country')"> </selectLinkToComponent>
                                  </div>
                                  <div class="form-group">
                                    <label class="form-label">{{tr("Province")}}</label>
                                    <selectLinkToComponent ref="modalAddress_Province" recordKey="modalAddress" :table="'Province'" :paste="'Code'" :showField="'Name'" :filters="provinceFilter" :selected="selectedAddress.ProvinceCode"  @update-value="updateSelectValues('Province')"> </selectLinkToComponent>
                                  </div>
                                  <div class="form-group">
                                       <label class="form-label">{{tr('City')}}</label>
                                       <selectLinkToComponent ref="modalAddress_City" recordKey="modalAddress"  :table="'City'" :paste="'Code'" :showField="'Code'"  :filters="cityFilter" :selected="selectedAddress.City" v-on:update-value="updateSelectValues('City')"> </selectLinkToComponent>
                                  </div>
                                  <div class="form-group">
                                       <label class="form-label">{{tr('Locality')}}</label>
                                       <input id="selectedAddressLocality" v-model="selectedAddress.Locality" type="text" class="form-control" aria-autocomplete="none" autocomplete="none">
                                  </div>
                                  <div class="form-group">
                                        <label class="form-label">{{tr('ZipCode')}}</label>
                                       <input id="selectedZipCode" v-model="selectedAddress.ZipCode" type="text" class="form-control" aria-autocomplete="none" autocomplete="none">
                                   </div>
                                  <hr>
                                </div>
                              </div>
                            </div>
                        </div>
                        <div class="modal-footer" >
                            <button type="submit" class="btn btn-success" :title="tr('Add')" :class="[Button,{disabled:activeDeliveryAddress(selectedAddress.Code)}]" @click="registerNewAddress()" :disabled="isloading"><i class="icon fas fa-save mr-2"></i>{{tr('Save')}}</button>
                            <button type="button" class="btn btn-secondary" data-dismiss="modal"  :disabled="isloading">{{tr('Close')}}</button>
                        </div>
                    </div>
                </div>
            </div>`;
    }
}

addressCreateComponent.registerComponent();
